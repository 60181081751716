import { Component, OnDestroy, OnInit } from '@angular/core';
import
{
  TextDifference,
  DiffType
} from '../../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import
{
  HighlightRect,
  ScrollPosition,
} from '../../moon-pdf-viewer/moon-pdf-viewer.component';
import { MoonPdfViewerService } from '../../moon-pdf-viewer/moon-pdf-viewer.service';

const EQUAL_DIFF_COLOR = 'rgba(220,220,220, 0.5)';
const INSERTED_DIFF_COLOR = 'rgba(0, 255, 0, 0.5)';
const DELETED_DIFF_COLOR = 'rgba(255, 0, 0, 0.5)';
export interface TextCompareMessageToParent
{
  action:
    'FULL_TEXT_READED' |
    'READY' |
    'SEARCH_NOT_FOUND' |
    'SCROLL_CHANGE' |
    'ZOOM_CHANGE' |
    'TEXT_SELECTED' |
    'EQUAL_DIFF_CLICKED' |
    'LEAVE_COMPARISON_MODE' |
    'HIGHLIGHT_DIFFS_DONE';
  payload?: string | ScrollPosition | number;
  iframeIndex?: number;
}

export interface TextCompareMessageToChildren
{
  action:
    'SET_INDEX' |
    'SET_PDF_SRC' |
    'HIGHLIGHT_DIFFS' |
    'SEARCH_TEXT' |
    'HIGHLIGHT_DIFFS_IN_SELECTION' |
    'SET_SCROLL' |
    'SET_ZOOM' |
    'FOCUS_EQUAL_DIFF' |
    'REMOVE_HIGHLIGHTS' |
    'SET_COMPARISON_MODE';
  payload: string | TextDifference[] | ScrollPosition | number;
}

interface DiffHighlight extends HighlightRect
{
  textDifference: TextDifference;
}

@Component({
  selector: 'app-pdf-viewer-iframe',
  templateUrl: './pdf-viewer-iframe.component.html',
  styleUrl: './pdf-viewer-iframe.component.scss',
  standalone: false
})
export class PdfViewerIframeComponent implements OnInit, OnDestroy
{
  private messageListener: (event: MessageEvent) => void;

  searchText: string = '';
  pdfSrc: string;
  scrollPosition: ScrollPosition;
  zoomLevel: number | string;
  zoomFactor: number;
  selectedParagraphIndex: null | number;

  highlights: DiffHighlight[] = [];

  clickAction: 'selectParagraph' | 'emitClickedHighlight' | null;

  iframeIndex = -1;

  constructor(private moonPdfViewerService: MoonPdfViewerService)
  {}

  ngOnInit()
  {
    this.messageListener = (event: MessageEvent) =>
    {
      if (event.origin !== window.location.origin)
      {
        return;
      }

      const data = event.data as TextCompareMessageToChildren;

      switch (data.action)
      {
        case 'SET_INDEX':
          this.iframeIndex = +data.payload;
          break;
        case 'SET_PDF_SRC':
          this.pdfSrc = data.payload as string;
          break;
        case 'HIGHLIGHT_DIFFS':
          this.highlightDifferences(data.payload as TextDifference[], false);
          break;
        case 'SEARCH_TEXT':
          this.searchText = data.payload as string;
          break;
        case 'HIGHLIGHT_DIFFS_IN_SELECTION':
          this.highlightDifferences(data.payload as TextDifference[], true);
          break;
        case 'REMOVE_HIGHLIGHTS':
          this.removeDiffHighlights();
          break;
        case 'SET_SCROLL':
          this.scrollPosition = data.payload as ScrollPosition;
          break;
        case 'SET_ZOOM':
          this.zoomLevel = data.payload as number | string;
          break;
        case 'FOCUS_EQUAL_DIFF':
          this.focusEqualDiff(data.payload as number);
          break;
        case 'SET_COMPARISON_MODE':
          this.onComparisonModeChange(data.payload as 'Document' | 'Selection' | 'Paragraph');
          break;
      }
    };

    window.addEventListener('message', this.messageListener);
    this.sendMsgToParent({ action: 'READY' });
  }

  ngOnDestroy()
  {
    if (this.messageListener)
    {
      window.removeEventListener('message', this.messageListener);
    }
  }

  onSearchFound(found: boolean)
  {
    if (!found)
    {
      const msg: TextCompareMessageToParent = { action: 'SEARCH_NOT_FOUND', payload: this.searchText, iframeIndex: this.iframeIndex };
      this.sendMsgToParent(msg);
    }
  }

  onScrollChange(event: ScrollPosition)
  {
    const msg: TextCompareMessageToParent = { action: 'SCROLL_CHANGE', payload: event, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onZoomLevelChange(zoom: number | string)
  {
    const msg: TextCompareMessageToParent = { action: 'ZOOM_CHANGE', payload: zoom, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onTextSelected(text: string)
  {
    const msg: TextCompareMessageToParent = { action: 'TEXT_SELECTED', payload: text, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  async onAllTextReaded(allText: string)
  {
    const msg: TextCompareMessageToParent = { action: 'FULL_TEXT_READED', payload: allText, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onHighlightClicked(highlightRect: HighlightRect)
  {
    const diffHighlight = this.highlights.find(h => h.rect === highlightRect.rect);
    const equalDiffs = this.highlights.filter(h => h.textDifference.type === DiffType.Equal);
    const index = equalDiffs.indexOf(diffHighlight);
    const msg: TextCompareMessageToParent = { action: 'EQUAL_DIFF_CLICKED', payload: index, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onRotationChange()
  {
    const msg: TextCompareMessageToParent = { action: 'LEAVE_COMPARISON_MODE', payload: null, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  private onComparisonModeChange(mode: 'Document' | 'Selection' | 'Paragraph' | null)
  {
    switch (mode)
    {
      case 'Document':
        this.clickAction = 'emitClickedHighlight';
        break;
      case 'Paragraph':
        this.removeDiffHighlights();
        this.searchText = '';
        this.clickAction = 'selectParagraph';
        break;
      case 'Selection':
      default:
        this.removeDiffHighlights();
        this.searchText = '';
        this.clickAction = null;
        break;
    }
  }

  private focusEqualDiff(index: number)
  {
    const equalDiffs = this.highlights.filter(h => h.textDifference.type === DiffType.Equal);
    if (index >= equalDiffs.length)
    {
      return;
    }
    const equalDiff = equalDiffs[index];
    this.moonPdfViewerService.focusHighlight(equalDiff);
  }

  private async highlightDifferences(diffs: TextDifference[], highlightInSelection: boolean)
  {
    const diffTypeToDiscard = this.iframeIndex === 1 ? DiffType.Inserted : DiffType.Deleted;
    const filteredDiffs = diffs.filter(d => d.type !== diffTypeToDiscard && d.text !== ' ');
    const highlights = await this.mapDiffsToHighlightsRects(filteredDiffs, highlightInSelection);
    this.highlights = highlights;

    const msg: TextCompareMessageToParent = { action: 'HIGHLIGHT_DIFFS_DONE', iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  private removeDiffHighlights()
  {
    this.highlights = [];
  }

  private sendMsgToParent(msg: TextCompareMessageToParent)
  {
    window.parent.postMessage(msg, '*');
  }

  private async mapDiffsToHighlightsRects(diffs: TextDifference[], highlightInSelection: boolean): Promise<DiffHighlight[]>
  {
    if (!diffs || diffs.length === 0)
    {
      return [];
    }

    await this.moonPdfViewerService.renderAllPages();

    const spansToAnalyze: HTMLElement[] = this.moonPdfViewerService.getTextSpans(highlightInSelection);

    let generalIndex = 0;
    const highlightData: DiffHighlight[] = [];

    spansToAnalyze.forEach((el: HTMLElement) =>
    {
      const text = el.textContent.normalize('NFKC');

      const pageDiv = el.closest('.page');
      const pageNumber = pageDiv ? parseInt(pageDiv.getAttribute('data-page-number'), 10) : 1;
      const canvasWrapper = pageDiv.querySelector('.canvasWrapper') as HTMLDivElement;
      const wrapperRect = canvasWrapper.getBoundingClientRect();

      for (let index = 0; index < text.length; index++)
      {
        const target = text[index];

        if (target === ' ')
        {
          continue;
        }

        const currentDifference = diffs[generalIndex];

        if (!currentDifference)
        {
          break;
        }

        let color: string;
        switch (currentDifference.type)
        {
          case DiffType.Inserted:
            color = INSERTED_DIFF_COLOR;
            break;
          case DiffType.Deleted:
            color = DELETED_DIFF_COLOR;
            break;
          case DiffType.Equal:
            color = EQUAL_DIFF_COLOR;
            break;
        }

        let charIndex = index;
        let node = el.firstChild;

        // Sometimes a span can contain another span inside it,
        // this could happen when there is a text selection by the search text functionality.
        // In those cases, we need to find the correct span to highlight.
        while (node && charIndex >= 0)
        {
          if (node.nodeType === Node.TEXT_NODE)
            {
              if (charIndex <= node.textContent.length)
              {
                break;
              }
              charIndex -= node.textContent.length;
            }
            node = node.nextSibling;
        }

        if (node)
        {
          const range = document.createRange();

          if (charIndex === node.textContent.length)
          {
            charIndex--;
          }

          range.setStart(node, charIndex);
          range.setEnd(node, charIndex + 1);


          const rects = range.getClientRects();
          const rect = rects[0];
          if (rect)
          {
            const highlightRect =
            {
              top: rect.top - wrapperRect.top,
              left: rect.left - wrapperRect.left,
              width: rect.width,
              height: rect.height
            };


            const previousHighlight = highlightData[highlightData.length - 1];
            if (previousHighlight &&
              Math.abs(previousHighlight.rect.top - highlightRect.top) <= 3 &&
              previousHighlight.rect.left + previousHighlight.rect.width <= highlightRect.left
            )
            {
              const closestRight = previousHighlight.rect.left + previousHighlight.rect.width;
              highlightRect.width += highlightRect.left - closestRight;
              highlightRect.left = closestRight;
            }

            highlightData.push(
            {
              pageNumber: pageNumber,
              rect: highlightRect,
              color: color,
              zoomFactor: this.zoomFactor,
              textDifference: currentDifference
            });
          }
        }
        generalIndex++;
      }
    });

    return highlightData;
  }
}
