<div class="moon-pdf-viewer">
    <ng-container *ngIf="!pdfJsViewer">
        <div *ngIf="busy" class="spinner">
            <mat-progress-spinner  diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div> 
        <pdf-viewer #pdfViewer class="pdf-viewer" [src]="src" [ngClass]="{'hand-cursor': panOnClickDrag}"
            [render-text]="false"
            [original-size]="false"
            [zoom]="zoom"
            [show-borders]="false"
            [zoom-scale]="'page-fit'"
            [page]="page"
            [show-all]="showAllPages"
            (page-rendered)="onPageRendered($event)"
            (after-load-complete)="onLoadComplete($event)"
            (wheel)="onMouseWheel($event)"
            (mousemove)="onDrag($event, pdfViewer)"
            (mousedown)="onDragStarted()"
            (mouseup)="onDragEnded()"
            (mouseleave)="onDragEnded()">
        </pdf-viewer>    
    </ng-container>

    <ng-container *ngIf="pdfJsViewer">
        <ngx-extended-pdf-viewer class="pdf-viewer" [src]="src"
            [showToolbar]="false"
            [showOpenFileButton]="true"
            [showZoomButtons]="true"
            [showZoomDropdown]="true"
            [handTool]="false"
            [showFindButton]="true"
            [showPropertiesButton]="true"
            [textLayer]="true"
            [enableDragAndDrop]="true"
            [(zoom)]="_zoom"
            [page]="currentPage"
            [rotation]="rotation"
            (pagesLoaded)="onPagesLoaded($event)"
            (currentZoomFactor)="onZoomFactorChange($event)"
            (zoomChange)="onZoomChange($event)"
            (pageRendered)="onPageRenderedPdfJs($event)"
            (pageChange)="currentPage = $event"
            (pdfLoaded)="onPdfLoaded()"
            (textLayerRendered)="onTextLayerRendered($event)"
            (updateFindState)="updateFindState($event)">
        </ngx-extended-pdf-viewer>

        @if (showLayersSelector)
        {
            <div class="layers-selector">
                @if (layersBusy)
                {
                    <div class="layers-busy">
                        <mat-progress-spinner diameter="32" color="accent" mode="indeterminate"></mat-progress-spinner>
                    </div>
                }
                @else
                {
                    @for(layer of layers; track layer)
                    {
                        <div class="layer">
                            <mat-checkbox (click)="toggleLayer(layer)" [checked]="layer.visible"></mat-checkbox>
                            <span>{{layer.name}}</span>
                        </div>
                    }
                }
            </div>
        }


        <div class="right-toolbar">
            <!-- <span class="toolbar-button alert-icon" *ngIf="pdfLoaded">
                <mat-icon class="notranslate">warning</mat-icon>
            </span>
            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}">
                <mat-icon class="notranslate">high_quality</mat-icon>
            </span> -->

            <!-- @for (toolbarButton of extraToolbarButtons; track toolbarButton)
            {
                <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="toolbarButton.action()" [matTooltip]="toolbarButton.tooltip">
                    <mat-icon class="notranslate">{{toolbarButton.icon}}</mat-icon>
                </span>
            } -->

            <span class="toolbar-button" (click)="showLayersSelector = !showLayersSelector" [ngClass]="{'disabled': !pdfLoaded || !layers || layers.length === 0}" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.layersSelector' | translate">
                <mat-icon class="notranslate material-icons-outlined">layers</mat-icon>
            </span>

            <span class="divider"></span>

            <span class="toolbar-input" [ngClass]="{'disabled': !pdfLoaded}" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.specificPage' | translate">
                <input type="text" [value]="currentPage" (change)="onCurrentPageChange($event)" [disabled]="!pdfLoaded">
            </span>

            <span class="toolbar-label" [ngClass]="{'disabled': !pdfLoaded}" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.totalPages' | translate">
                {{totalPagesCount}}
            </span>
            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded || currentPage === 1}" (click)="goToPage(currentPage - 1)" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.previousPage' | translate">
                <mat-icon class="notranslate material-icons-outlined">arrow_drop_up</mat-icon>
            </span>
            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded || currentPage === totalPagesCount}" (click)="goToPage(currentPage + 1)" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.nextPage' | translate">
                <mat-icon class="notranslate material-icons-outlined">arrow_drop_down</mat-icon>
            </span>

            <span class="divider"></span>

            <!-- <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}">
                <mat-icon class="notranslate material-icons-outlined">link</mat-icon>
            </span> -->

            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="zoomInPdfJs()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.zoomIn' | translate">
                <mat-icon class="notranslate material-icons-outlined">zoom_in</mat-icon>
            </span>
    
            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="zoomOutPdfJs()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.zoomOut' | translate">
                <mat-icon class="notranslate material-icons-outlined">zoom_out</mat-icon>
            </span>
            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="zoomPageFit()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.fitPage' | translate">
                <span class="notranslate material-symbols-outlined">fit_page_height</span>
            </span>
            <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="rotate()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.rotate' | translate">
                <mat-icon class="notranslate material-icons-outlined">refresh</mat-icon>
            </span>

            <span class="divider"></span>

            <!-- <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}">
                <mat-icon class="notranslate material-icons-outlined">file_download</mat-icon>
            </span> -->

        </div>

        <!-- <div class="left-toolbar">
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">near_me</span>
            </span>
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">add_comment</span>
            </span>
        </div> -->

        <!-- ToDo implement active logic -->
        <div class="bottom-toolbar">
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">description</span>
            </span>
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">straighten</span>
            </span>
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">barcode_scanner</span>
            </span>
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">compare</span>
            </span>
            <span class="toolbar-button">
                <span class="notranslate material-symbols-outlined">difference</span>
            </span>
            <span class="toolbar-button active">
                <span class="notranslate material-symbols-outlined">text_compare</span>
            </span>
        </div>
    </ng-container>
</div>

