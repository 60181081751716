import { Injectable } from '@angular/core';
import { HighlightRect } from './moon-pdf-viewer.component';

@Injectable({
  providedIn: 'root'
})
export class MoonPdfViewerService {

  private moonPdfViewerRenderAllPages?: () => Promise<void>;
  private moonPdfViewerGetTextSpans?: (highlightedSpans?: boolean) => HTMLElement[];
  private moonPdfViewerFocusHighlight?: (highlight: HighlightRect) => void;

  registerRenderAllPages(renderAllPages: () => Promise<void>): void
  {
    this.moonPdfViewerRenderAllPages = renderAllPages;
  }

  registerGetTextSpans(getTextSpans: () => HTMLElement[]): void
  {
    this.moonPdfViewerGetTextSpans = getTextSpans;
  }

  registerFocusHighlight(focusHighlight: (highlight: HighlightRect) => void): void
  {
    this.moonPdfViewerFocusHighlight = focusHighlight;
  }

  async renderAllPages(): Promise<void>
  {
    if (!this.moonPdfViewerRenderAllPages)
    {
      console.error('moonPdfViewerRenderAllPages is not set');
      return;
    }
    await this.moonPdfViewerRenderAllPages();
  }

  getTextSpans(highlightedSpans?: boolean): HTMLElement[]
  {
    if (!this.moonPdfViewerGetTextSpans)
    {
      console.error('moonPdfViewerGetTextSpans is not set');
      return [];
    }
    return this.moonPdfViewerGetTextSpans(highlightedSpans);
  }

  focusHighlight(highlight: HighlightRect): void
  {
    if (!this.moonPdfViewerFocusHighlight)
    {
      console.error('moonPdfViewerFocusHighlight is not set');
      return;
    }
    this.moonPdfViewerFocusHighlight(highlight);
  }
}
