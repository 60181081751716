<div class="pdf-viewer-iframe">
  <app-moon-pdf-viewer class="pdf-viewer"
    [src]="pdfSrc"
    [pdfJsViewer]="true"
    [searchText]="searchText"
    [scrollPosition]="scrollPosition"
    [zoomLevel]="zoomLevel"
    [highlights]="highlights"
    [clickAction]="clickAction"
    (rotationChange)="onRotationChange()"
    (highlightClicked)="onHighlightClicked($event)"
    (allTextReaded)="onAllTextReaded($event)"
    (searchFound)="onSearchFound($event)"
    (scrollChange)="onScrollChange($event)"
    (zoomLevelChange)="onZoomLevelChange($event)"
    (textSelected)="onTextSelected($event)"
    (zoomFactorChange)="zoomFactor = $event">
  </app-moon-pdf-viewer>
</div>
