import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FindState,
  NgxExtendedPdfViewerService,
  PageRenderedEvent,
  PagesLoadedEvent,
  PdfLayer,
  TextLayerRenderedEvent
} from 'ngx-extended-pdf-viewer';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { MoonPdfViewerService } from './moon-pdf-viewer.service';
export interface ScrollPosition
{
  scrollTop: number;
  scrollLeft: number;
}
export interface HighlightRect
{
  rect: { left: number; top: number; width: number; height: number };
  pageNumber: number;
  zoomFactor: number;
  color: string;
}

// export interface ToolbarButton
// {
//   icon: string;
//   tooltip: string;
//   action: () => void;
// }

const FOCUS_HIGHLIGHT_BORDER_COLOR = 'rgb(55, 55, 100)';
const FOCUS_HIGHLIGHT_FILL_COLOR = 'rgba(55, 55, 100, 0.3)';

@Component({
    selector: 'app-moon-pdf-viewer',
    templateUrl: './moon-pdf-viewer.component.html',
    styleUrls: ['./moon-pdf-viewer.component.scss'],
    standalone: false
})
export class MoonPdfViewerComponent implements OnInit, AfterViewInit, OnDestroy
{
  @Input() src!: string;
  @Input() page: number = 1;
  @Input() showAllPages: boolean;
  @Input() panOnClickDrag: boolean = true;
  @Input() set searchText(text: string)
  {
    this.search(text);
  }

  @Input() pdfJsViewer: boolean = false;

  @Input() set highlights(highlights: HighlightRect[])
  {
    if (!highlights || highlights.length === 0)
    {
      this.removeAllHighlights();
      return;
    }
    this._highlights = highlights;
    this.pageDivs.forEach((div, pageNumber) =>
    {
      this.highlightRects(this._highlights, pageNumber);
    });
  }

  @Input() set clickAction(action: 'selectParagraph' | 'emitClickedHighlight' | null)
  {
    this.onClickAction = action;
    if (!action)
    {
      this.setClickListener(false);
      this.setMouseUpListener(true);
    }
    else
    {
      this.setClickListener(true);
      this.setMouseUpListener(false);
    }
  }

  // @Input() extraToolbarButtons: ToolbarButton[] = [];

  @Input() set zoomLevel(zoom: number | string)
  {
    const zoomNumber: number = isNaN(zoom as number) ? 100 : zoom as number;
    this.setZoomLevel(zoomNumber);
  }
  @Output() zoomLevelChange: EventEmitter<number | string> = new EventEmitter<number | string>();

  @Input() set scrollPosition(scroll: ScrollPosition)
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.scrollTo(scroll.scrollLeft, scroll.scrollTop);
    }
  }
  @Output() scrollChange: EventEmitter<ScrollPosition> = new EventEmitter<ScrollPosition>();

  @Output() pageRendered: EventEmitter<HTMLCanvasElement> = new EventEmitter<HTMLCanvasElement>();
  @Output() totalPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() allTextReaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchFound: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() textSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() highlightClicked: EventEmitter<HighlightRect> = new EventEmitter<HighlightRect>();
  @Output() zoomFactorChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() rotationChange: EventEmitter<0 | 90 | 180 | 270> = new EventEmitter<0 | 90 | 180 | 270>();

  onClickAction: 'selectParagraph' | 'emitClickedHighlight' | null = null;
  commentMode: 'note' | 'highlight' | 'underline' | 'strikeout' | null = null;

  pageDivs: Map<number, HTMLElement> = new Map<number, HTMLElement>();
  textLayers: Map<number, HTMLElement> = new Map<number, HTMLElement>();
  _highlights: HighlightRect[] = [];
  focusedHighlightElement: HTMLElement;
  totalPagesCount: number = 0;
  currentPage: number = 0;
  rotation: 0 | 90 | 180 | 270 = 0;
  pdfLoaded: boolean = false;
  layersBusy: boolean = false;
  layers: PdfLayer[] = [];
  showLayersSelector: boolean = false;
  private zoomChangePromise: Promise<void> | null = null;
  private resolveZoomChangePromise: (() => void) | null = null;

  private destroy$ = new Subject<void>();
  private clickSubject = new Subject<MouseEvent>();

  // Obsolete
  zoom: number = 1;

  _zoom: number | string = 'auto';
  zoomFactor: number;
  isDragging: boolean;
  busy = true;

  private viewerContainer: HTMLElement | null = null;

  constructor(
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
    private el: ElementRef,
    private moonPdfViewerService: MoonPdfViewerService)
  {
  }

  ngOnInit(): void
  {
    this.moonPdfViewerService.registerRenderAllPages(this.renderAllPages.bind(this));
    this.moonPdfViewerService.registerGetTextSpans(this.getTextSpans.bind(this));
    this.moonPdfViewerService.registerFocusHighlight(this.focusHighlight.bind(this));
  }

  ngAfterViewInit(): void
  {
    // Listen to click events on the pdf text layer
    this.clickSubject.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe((event: MouseEvent) =>
    {
      const target = event.target as HTMLElement;

      if (target.classList.contains('textLayer'))
      {
        return;
      }

      if (this.onClickAction === 'emitClickedHighlight')
      {
        const highlight = this.getHighlightOnPosition(event, target);
        if (highlight)
        {
          this.highlightClicked.emit(highlight);
        }
      }
      else if (this.onClickAction === 'selectParagraph')
      {
        const text = this.highlightParagraphAndGetText(target);
        this.textSelected.emit(text);
      }
    });
  }

  ngOnDestroy()
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.removeEventListener('scroll', this.onScroll);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPdfLoaded()
  {
    this.pdfLoaded = false;
    this.viewerContainer = document.getElementById('viewerContainer');
    if (this.viewerContainer)
    {
      // Just in case
      this.viewerContainer.removeEventListener('scroll', this.onScroll);
      this.viewerContainer.addEventListener('scroll', this.onScroll);
    }
    this.currentPage = 1;
    this.totalPagesCount = 1;
    this.removeAllHighlights();
    this.textLayers.clear();
    this.pageDivs.clear();
    this.showLayersSelector = false;
    this.loadLayers();
    if (this.focusedHighlightElement)
    {
      this.focusedHighlightElement.remove();
    }
    this.pdfLoaded = true;
    console.log('PDF Loaded');
  }

  onZoomFactorChange(event: number)
  {
    this.zoomFactor = event;
    if (this.focusedHighlightElement)
    {
      this.focusedHighlightElement.remove();
    }

    if (this.resolveZoomChangePromise)
    {
      this.resolveZoomChangePromise();
      this.zoomChangePromise = null;
      this.resolveZoomChangePromise = null;
    }

    this.zoomFactorChange.emit(this.zoomFactor);
  }

  onPageRenderedPdfJs(event: PageRenderedEvent)
  {
    const pageNumber = event.pageNumber;
    const pageDiv = event.source.div as HTMLDivElement;
    this.pageDivs.set(pageNumber, pageDiv);

    this.highlightRects(this._highlights, event.pageNumber);
  }

  onCurrentPageChange(changeEvent: Event)
  {
    const target = changeEvent.target as HTMLInputElement;
    const pageNumber = parseInt(target.value, 10);
    const isValid = !isNaN(pageNumber) && pageNumber > 0 && pageNumber <= this.totalPagesCount;
    if (!isValid)
    {
      target.value = this.currentPage.toString();
      return;
    }
    else
    {
      this.currentPage = pageNumber;
    }
  }

  goToPage(pageNumber: number)
  {
    if (pageNumber < 1 || pageNumber > this.totalPagesCount)
    {
      return;
    }
    this.currentPage = pageNumber;
  }

  zoomInPdfJs()
  {
    let currentZoom = this._zoom as number;
    if (isNaN(currentZoom))
    {
      currentZoom = 100;
    }
    this.setZoomLevel(currentZoom + 25);
  }

  zoomOutPdfJs()
  {
    let currentZoom = this._zoom as number;
    if (isNaN(currentZoom))
    {
      currentZoom = 100;
    }
    this.setZoomLevel(currentZoom - 25);
  }

  zoomPageFit()
  {
    this.setZoomLevel('page-fit');
  }

  rotate()
  {
    this.rotation = ((this.rotation + 90) % 360) as 0 | 90 | 180 | 270;
    this.rotationChange.emit(this.rotation);
  }

  async onTextLayerRendered(event: TextLayerRenderedEvent)
  {
    this.textLayers.set(event.pageNumber, event.layer.div);
  }

  async onPagesLoaded(event: PagesLoadedEvent)
  {
    try
    {
      console.log('onPagesLoaded');
      this.totalPagesCount = event.pagesCount;

      await this.renderAllPages();
      const fullText = await this.getAllText();

      this.allTextReaded.emit(fullText);
      this.totalPages.emit(event.pagesCount);

      console.log('All text readed');
    }
    catch (error)
    {
      console.error('Error reading all text', error);
    }

  }

  updateFindState(event: FindState)
  {
    if (event === FindState.NOT_FOUND)
    {
      this.searchFound.emit(false);
    }
    else if (event === FindState.FOUND)
    {
      this.searchFound.emit(true);
    }
  }


  async toggleLayer(layer: PdfLayer)
  {
    this.layersBusy = true;
    await this.ngxExtendedPdfViewerService.toggleLayer(layer.layerId);
    layer.visible = !layer.visible;
    this.layersBusy = false;
  }

  private setZoomLevel(zoom: number | string)
  {
    this._zoom = zoom;
    this.zoomChangePromise = new Promise<void>((resolve) =>
    {
      this.resolveZoomChangePromise = resolve;
    });
  }

  private async loadLayers()
  {
    try
    {
      this.layersBusy = true;
      this.layers = [];
      this.layers = await this.ngxExtendedPdfViewerService.listLayers();
    }
    catch (error)
    {
      // This is a non-critical error, just log it (some documents may not have layers info)
      console.log('Error loading layers', error);
    }
    finally
    {
      this.layersBusy = false;
    }
  }

  /**
   * Emit events based on the clickAction
   * see clickSubject in ngAfterViewInit
   */
  private setClickListener(listen: boolean)
  {
    if (listen)
    {
      this.textLayers.forEach((layer) =>
      {
        layer.addEventListener('click', (event: MouseEvent) => { this.clickSubject.next(event); });
        layer.querySelectorAll('span').forEach((span) =>
        {
          span.classList.add('pointer-mode');
        });
      });
    }
    else
    {
      this.textLayers.forEach((layer) =>
      {
        layer.removeAllListeners('click');
        layer.querySelectorAll('span').forEach((span) =>
        {
          span.classList.remove('pointer-mode');
        });
      });
    }
  }

  /**
   * Emit events on text selection with the selected text
   */
  private setMouseUpListener(listen: boolean)
  {
    if (listen)
    {
      this.textLayers.forEach((layer) =>
      {
        layer.addEventListener('mouseup', () =>
        {
          const selection = window.getSelection()?.toString().trim();
          if (selection)
          {
            this.textSelected.emit(selection);
          }
        });
      });
    }
    else
    {
      this.textLayers.forEach((layer) =>
      {
        layer.removeAllListeners('mouseup');
      });
    }
  }

  private async renderAllPages()
  {
    for (let i = 0; i < this.totalPagesCount; i++)
    {
      if (!this.ngxExtendedPdfViewerService.hasPageBeenRendered(i))
      {
        await this.ngxExtendedPdfViewerService.renderPage(i);
      }
    }
  }

  private getHighlightOnPosition(event: MouseEvent, target: HTMLElement): HighlightRect | undefined
  {
    const pageDiv = target.closest('.page');
    const pageNumber = pageDiv ? parseInt(pageDiv.getAttribute('data-page-number'), 10) : 1;

    const canvasWrapper = pageDiv.querySelector('.canvasWrapper') as HTMLDivElement;
    const wrapperRect = canvasWrapper.getBoundingClientRect();
    const rect = target.getBoundingClientRect();
    const clickX = rect.left - wrapperRect.left + (event.clientX - rect.left);
    const clickY = rect.top - wrapperRect.top + (event.clientY - rect.top);

    const highlightInsideClickedTarget = this._highlights.find(h =>
      h.pageNumber === pageNumber &&
      h.rect.left * (this.zoomFactor / h.zoomFactor) <= clickX &&
      (h.rect.left + h.rect.width) * (this.zoomFactor / h.zoomFactor) >= clickX &&
      h.rect.top * (this.zoomFactor / h.zoomFactor) <= clickY &&
      (h.rect.top + h.rect.height) * (this.zoomFactor / h.zoomFactor) >= clickY);
    return highlightInsideClickedTarget;
  }

  private focusHighlight(highlight: HighlightRect)
  {
    const rect = highlight.rect;
    const scale = this.getScale(highlight.zoomFactor);
    const windowScale = window.devicePixelRatio;

    const margin = 5 * scale;
    const x = ((rect.left * scale) - margin) / windowScale;
    const y = (rect.top * scale) / windowScale;
    const adjustedWidth = (rect.width * scale) + (margin * 2);
    const adjustedHeight = (rect.height) * scale;

    const pageDiv = this.pageDivs.get(highlight.pageNumber);
    const canvasWrapper = pageDiv.querySelector('.canvasWrapper') as HTMLDivElement;

    if (this.focusedHighlightElement)
    {
      this.focusedHighlightElement.remove();
    }

    const highlightDiv = document.createElement('div');
    highlightDiv.style.position = 'absolute';
    highlightDiv.style.left = `${x}px`;
    highlightDiv.style.top = `${y}px`;
    highlightDiv.style.width = `${adjustedWidth}px`;
    highlightDiv.style.height = `${adjustedHeight}px`;
    highlightDiv.style.backgroundColor = FOCUS_HIGHLIGHT_FILL_COLOR;
    highlightDiv.style.border = `2px solid ${FOCUS_HIGHLIGHT_BORDER_COLOR}`;
    highlightDiv.style.opacity = '0.7';

    canvasWrapper.appendChild(highlightDiv);
    this.focusedHighlightElement = highlightDiv;

    const pageXOffset = pageDiv.offsetLeft;
    const pageYOffset = pageDiv.offsetTop;

    const scrollX = x + pageXOffset - (this.viewerContainer.clientWidth / 2) + (adjustedWidth / 2);
    const scrollY = y + pageYOffset - (this.viewerContainer.clientHeight / 2) + (adjustedHeight / 2);
    this.viewerContainer.scrollTo(scrollX, scrollY);
  }

  private removeAllHighlights()
  {
    this._highlights = [];
    this.clearParagraphHighlights();
    this.resetHighlights();
  }

  async resetHighlights()
  {
    if (!this._zoom)
    {
      this._zoom = 'auto';
      return;
    }
    const currentZoom = this._zoom;

    if (!isNaN(this._zoom as number))
    {
      this._zoom = this._zoom as number + 0.01;
    }
    else
    {
      this._zoom = 500;
    }
    await this.delay(100);
    this._zoom = currentZoom;
  }

  private async highlightRects(highlights: HighlightRect[], pageNumber: number)
  {
    if (!highlights || highlights.length === 0)
    {
      return;
    }

    // Wait for the zoom change to finish before rendering the highlights
    if (this.zoomChangePromise)
    {
      await this.zoomChangePromise;
    }

    const pageDiv = this.pageDivs.get(pageNumber);
    const canvasWrapper = pageDiv.querySelector('.canvasWrapper') as HTMLDivElement;
    const canvas = canvasWrapper.querySelector('canvas') as HTMLCanvasElement;
    const pageHighlights = this._highlights.filter(h => h.pageNumber === pageNumber);

    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'multiply';
    pageHighlights.forEach(highlight =>
    {
      const rect = highlight.rect;
      const scale = this.getScale(highlight.zoomFactor);

      const adjustedLeft = rect.left * scale;
      const adjustedTop = rect.top * scale;
      const adjustedWidth = rect.width * scale;
      const adjustedHeight = rect.height * scale;

      ctx.fillStyle = highlight.color;
      ctx.fillRect(adjustedLeft, adjustedTop, adjustedWidth, adjustedHeight);
    });
    ctx.globalCompositeOperation = 'source-over';
  }

  private getScale(highlightZoomFactor: number): number
  {
    const windowScale = window.devicePixelRatio;
    return (this.zoomFactor / highlightZoomFactor) * windowScale;
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private onScroll = (event: Event): void =>
  {
    const target = event.target as HTMLElement;
    this.scrollChange.emit({ scrollTop: target.scrollTop, scrollLeft: target.scrollLeft });
  };

  private search(text: string)
  {
    this.ngxExtendedPdfViewerService.find(text,
    {
      highlightAll: true,
      matchCase: false,
      wholeWords: false,
    });
  }

  private async getAllText()
  {
    let allText = '';
    for (let i = 1; i <= this.totalPagesCount; i++)
    {
      const text = await this.ngxExtendedPdfViewerService.getPageAsText(i);
      allText += ` ${text}`;
    }
    return allText;
  }

  private highlightParagraphAndGetText(target: HTMLElement): string
  {
    this.clearParagraphHighlights();
    const paragraphSpans = this.getParagraphSpans(target);
    paragraphSpans.forEach((span) => span.classList.add('highlight'));

    let text = paragraphSpans.map((span) => span.textContent).join(' ');
    text = text.replace(/\s+/g, ' ').trim();
    return text;
  }

  private clearParagraphHighlights()
  {
    const highlightedSpans = this.el.nativeElement.querySelectorAll('.highlight');
    highlightedSpans.forEach((span) => span.classList.remove('highlight'));
  }

  private getParagraphSpans(target: HTMLElement): HTMLElement[]
  {
    let spansToAnalyze: HTMLElement[] = this.getTextSpans();
    if (spansToAnalyze?.length === 0)
    {
      return [];
    }
    spansToAnalyze.splice(spansToAnalyze.indexOf(target), 1);

    const nearbySpans: Set<HTMLElement> = new Set([]);
    const queue: HTMLElement[] = [target];
    const threshold = this.getThreshold(spansToAnalyze);

    while (queue.length > 0)
    {
      const currentTarget = queue.shift();
      spansToAnalyze = spansToAnalyze.filter(span => span !== currentTarget);
      nearbySpans.add(currentTarget);
      const newNearbySpans = this.getNearbySpans(currentTarget, spansToAnalyze, threshold.thresholdTop, threshold.thresholdLeft);

      for (const span of newNearbySpans)
      {
        spansToAnalyze = spansToAnalyze.filter(s => span !== s);

        let isDuplicated: boolean = false;
        nearbySpans.forEach((nearbySpan) =>
        {
          const spanRect = span.getBoundingClientRect();
          const nearbySpanRect = nearbySpan.getBoundingClientRect();

          if (spanRect.top === nearbySpanRect.top && spanRect.left === nearbySpanRect.left)
          {
            isDuplicated = true;
            return;
          }
        });

        if (!isDuplicated)
        {
          nearbySpans.add(span);
          queue.push(span);
        }
      }
    }

    const orderedNearbySpans = Array.from(nearbySpans).sort((a, b) =>
    {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();

      const margin = 2; // Consider a small margin for top comparison

      if (Math.abs(rectA.top - rectB.top) <= margin)
      {
        return rectA.left - rectB.left;
      }
      return rectA.top - rectB.top;
    });

    return Array.from(orderedNearbySpans);
  }

  private getNearbySpans(target: HTMLElement, spans: HTMLElement[], maxDistanceY: number, maxDistanceX: number): HTMLElement[]
  {
    maxDistanceX = maxDistanceX > 0 ? maxDistanceX : maxDistanceY;
    if (!spans || spans.length === 0)
    {
      return [];
    }

    const targetRect = target.getBoundingClientRect();

    const targetText = target.textContent.trim();
    const targetTextEndWithStop = targetText.endsWith('.');

    const closestSpans = spans.filter((span) =>
    {
      const spanRect = span.getBoundingClientRect();
      const isCloseHorizontally =
        Math.abs(spanRect.left - targetRect.left) < maxDistanceX ||
        Math.abs(spanRect.left - targetRect.right) < maxDistanceX ||
        Math.abs(spanRect.right - targetRect.left) < maxDistanceX;
      const isCloseVertically = Math.abs(spanRect.top - targetRect.top) < maxDistanceY;

      const isSpanUnderCurrentSpan = spanRect.top > targetRect.top;
      const spanText = span.textContent.trim();
      const spanTextEndWithStop = spanText.endsWith('.');
      const isSpanOverCurrentSpan = spanRect.top < targetRect.top;

      const isFullStop = (targetTextEndWithStop && isSpanUnderCurrentSpan) || (spanTextEndWithStop && isSpanOverCurrentSpan);

      return !isFullStop && isCloseHorizontally && isCloseVertically;

    });
    return closestSpans;
  }

  /**
   * Analyzes the distance between spans and calculates the average for
   * those that are close (distance less than 100px and greater than 1px)
   */
  private getThreshold(spans: HTMLElement[]): { thresholdTop: number; thresholdLeft: number }
  {
    if (spans.length < 2)
    {
      return { thresholdTop: 0, thresholdLeft: 0 };
    }

    let totalTopDistance = 0;
    let totalLeftDistance = 0;
    let countT = 0;
    let countL = 0;

    for (let i = 0; i < spans.length - 1; i++)
    {
      const rect1 = spans[i].getBoundingClientRect();
      const rect2 = spans[i + 1].getBoundingClientRect();

      const topDistance = Math.abs(rect2.top - rect1.top);
      if (topDistance > 1 && topDistance < 100)
      {
        totalTopDistance += topDistance;
        countT++;
      }
      const leftDistance = Math.abs(rect2.left - rect1.left);
      // Texts inside the same paragraph should not be too far apart horizontally
      if (leftDistance > 1 && leftDistance < 40)
      {
        totalLeftDistance += leftDistance;
        countL++;
      }
    }

    const thresholdTop = totalTopDistance / countT;
    const thresholdLeft = totalLeftDistance / countL;

    return { thresholdTop, thresholdLeft };
  }

  private getTextSpans(highlightedSpans?: boolean): HTMLElement[]
  {
    let spans: HTMLElement[] = [];
    this.textLayers.forEach((layer) =>
    {
      if (!layer)
      {
        return;
      }
      spans = spans.concat(Array.from(layer.querySelectorAll('span')));
    });
    spans = spans.filter((span) => !span.classList.contains('markedContent'));

    spans = this.removeDuplicateSpans(spans);

    if (highlightedSpans)
    {
      const spansWithHighlights = spans.filter((span) => span.classList.contains('highlight'));
      spans = spansWithHighlights;
    }

    return spans;
  }

  removeDuplicateSpans(spans: HTMLElement[]): HTMLElement[]
  {
    return spans.reduce((uniqueSpans: HTMLElement[], currentSpan) =>
    {
      const text = currentSpan.textContent?.trim() || '';
      const rect = currentSpan.getBoundingClientRect();
      const width = Math.round(rect.width);
      const height = Math.round(rect.height);
      const top = Math.round(rect.top);
      const left = Math.round(rect.left);

      const existingSpan = uniqueSpans.find(span =>
      {
        const existingRect = span.getBoundingClientRect();
        const existingWidth = Math.round(existingRect.width);
        const existingHeight = Math.round(existingRect.height);
        const existingTop = Math.round(existingRect.top);
        const existingLeft = Math.round(existingRect.left);

        return (
          span.textContent?.trim() === text &&
          Math.abs(existingWidth - width) <= 1 &&
          Math.abs(existingHeight - height) <= 1 &&
          Math.abs(existingTop - top) <= 1 &&
          Math.abs(existingLeft - left) <= 1
        );
      });

      if (existingSpan)
      {
        const existingRect = existingSpan.getBoundingClientRect();
        if ((width * height) > (existingRect.width * existingRect.height))
        {
          const index = uniqueSpans.indexOf(existingSpan);
          uniqueSpans[index] = currentSpan;
        }
      }
      else
      {
        uniqueSpans.push(currentSpan);
      }
      return uniqueSpans;
    }, []);
  }


  /**
   *
   * OLD PDF VIEWER FUNCTIONS - TO BE REMOVED OR TRANSLATED TO THE NEW ONE (ngx-extended-pdf-viewer)
   *
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrag(event: MouseEvent, pdfViewer: any)
  {
    if (!this.panOnClickDrag)
    {
      return;
    }
    if (this.isDragging)
    {
      const pdfContainer = pdfViewer.element.nativeElement.children[0] as HTMLElement;
      const x = pdfContainer.scrollLeft - event.movementX;
      const y = pdfContainer.scrollTop - event.movementY;
      pdfContainer.scrollTo(x, y);
    }
  }

  onDragStarted()
  {
    this.isDragging = true;
  }

  onDragEnded()
  {
    this.isDragging = false;
  }

  onZoomChange(event: number | string)
  {
    this._zoom = event;
    this.zoomLevelChange.emit(event);
  }

  onMouseWheel(event: WheelEvent)
  {
    event.preventDefault();
    if (event.deltaY < 0)
    {
      this.zoomIn();
    }
    else
    {
      this.zoomOut();
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#after-load-complete
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoadComplete(event: any)
  {
    this.totalPages.emit(event._pdfInfo.numPages);
  }

  zoomIn()
  {
    if (this.zoom < 8)
    {
      this.zoom += 0.25;
    }
  }

  zoomOut()
  {
    if (this.zoom >= 0.50)
    {
      this.zoom -= 0.25;
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#page-rendered
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageRendered(event: any)
  {
    if (event.pageNumber === this.page)
    {
      this.busy = false;
      this.pageRendered.emit(event.source.canvas);
    }
  }
}
